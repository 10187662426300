import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_ORG_USERS } from "./services/graphql";
import Loader from "../../components/Loader";
import { AuthContext } from "../../services/auth/AuthProvider";

import TeamContent from "./TeamContent";

const Team = () => {

    const { currentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(GET_ORG_USERS, {
        fetchPolicy: 'network-only',
        variables: {
            organizationId: currentUser?.organization?.id,
            showAllUsers: true
        }
    })

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.organization?.id) {

        const { organization } = data;
        return (
            <>
                <TeamContent organization={organization} users={organization.adminsAndLeaders} />

            </>
        )
    }
    return null;
}

export default Team;