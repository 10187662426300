import React, { useState } from "react";
import styles from '../ViewCase.module.scss';
import { Edit, Trash2, CornerDownRight } from "react-feather";
import { useMutation } from "@apollo/react-hooks";
import { TOGGLE_PERSONA_REQUIREMENT, DELETE_MEMBERSHIP, GET_CASE } from "../../../services/grapqhl";
import { CIRCLE_MODE, getShortRoleLabel } from "../../../../../utils/constants";
import FormErrors from "../../../../../components/FormErrors";
import { CIRCLE_STATUS } from "../../../../../utils/constants";
import ParticipantEdit from "./ParticipantEdit";
import SideModal from "../../../../../components/SideModal";
import Modal from "../../../../../components/Modal";

const MemberItem = ({ member, circle, isSupport = null, supportingUserId = null }) => {

    const [editMember, setEditMember] = useState(null);
    const [deleteMember, setDeleteMember] = useState(false);
    const [viewMember, setViewMember] = useState(false);

    const [formErrors, setFormErrors] = useState([]);
    const [deleteFormErrors, setDeleteFormErrors] = useState([]);

    const [togglePersona, { loading }] = useMutation(TOGGLE_PERSONA_REQUIREMENT, {
        onCompleted: data => {
            if (data?.togglePersonaRequirement.errors?.length > 0) {
                setFormErrors(data.togglePersonaRequirement.errors);
                return;
            }
        }
    });

    const [deleteMembership, { loading: deleting }] = useMutation(DELETE_MEMBERSHIP, {
        onCompleted: data => {
            if (data?.deleteCircleMembershipAndSupportUsers.errors?.length > 0) {
                setDeleteFormErrors(data.deleteCircleMembershipAndSupportUsers.errors);
                return;
            }

            if (data?.deleteCircleMembershipAndSupportUsers.deleted) {
                setDeleteMember(false);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_CASE, variables: { circleId: circle.id } }]
    });

    const handleChange = ({ target: { checked } }) => {
        togglePersona({
            variables: {
                circleMembershipId: member.id,
                requiresPersona: checked
            }
        })
    }


    return (
        <>
            <div className={`${styles.member} ${isSupport ? styles.memberSupporting : styles.memberMain}`}>
                {isSupport && <CornerDownRight size={18} className="mr-1" />}

                {circle.mode === CIRCLE_MODE.restorative &&
                    <div className={`${styles.memberLabel} label label-muted`}>{getShortRoleLabel(member.role)}</div>
                }
                <button onClick={() => setViewMember(true)} className={`${styles.memberName} link-reverse`}>{`${member.user?.firstName} ${member.user?.lastName}`}</button>

                {formErrors && <FormErrors errors={formErrors} />}


                <div className={`${styles.memberSide} ${circle.status >= CIRCLE_STATUS.opening ? styles.memberSideStarted : ''}`}>
                    <div className={styles.memberPersona}>
                        <div className="basic-form__group basic-form__group--check mb-0 justify-content-center">
                            <input
                                type="checkbox"
                                className="switchToggle"
                                name={`persona${member.id}`}
                                id={`persona${member.id}`}
                                onChange={handleChange}
                                checked={member.requiresPersona}
                                disabled={circle?.status >= CIRCLE_STATUS.started || loading}
                            />
                            <label className="switchLabel" htmlFor={`persona${member.id}`}>&nbsp;</label>
                        </div>
                    </div>

                    {circle?.status < CIRCLE_STATUS.opening &&
                        <>

                            <div className={styles.memberLabel}>
                                <button type="button" onClick={() => setEditMember(true)}><Edit size={20} /></button>
                            </div>
                            <div className={styles.memberLabel}>
                                <button type="button" onClick={() => setDeleteMember(member)}><Trash2 size={20} /></button>
                            </div>
                        </>
                    }
                </div>
            </div>
            {editMember &&
                <SideModal closeModal={() => setEditMember(false)}>
                    <ParticipantEdit
                        circle={circle}
                        role={member.role}
                        member={member}
                        close={() => setEditMember(false)}
                    />
                </SideModal>
            }

            {deleteMember && (
                <Modal closeModal={() => setDeleteMember(false)}>
                    <div className="text-center">
                        <h3 className="mb-3">Are you sure you want to delete {member.user?.firstName} {member.user?.lastName}?</h3>
                        {deleteFormErrors && <FormErrors errors={deleteFormErrors} />}
                        <button disabled={deleting} onClick={() =>
                            deleteMembership({
                                variables: {
                                    circleMembershipId: member.id
                                }
                            })
                        } className="btn mr-2">Yes</button>
                        <button onClick={() => setDeleteMember(false)} className="btn btn-outline">No</button>
                    </div>
                </Modal>
            )}

            {viewMember && (
                <SideModal closeModal={() => setViewMember(false)}>
                    <p className='bold lead mb-2'>{member.user.firstName} {member.user.lastName}</p>

                    <p className='small text-muted'>Address</p>
                    <p className='mb-2'>{member.user.userProfile?.address}</p>
                    {member.user.email &&

                        <>
                            <p className='small text-muted'>Email address</p>
                            <p className='mb-2'>{member.user.email} <span className="ml-2 small">Not editable</span></p>

                        </>
                    }

                    <p className='small text-muted'>Contact number</p>
                    <p className='mb-2'>{member.user.userProfile?.contactNumber || '--'}</p>
                    {!member.user.email && <>
                        <p className='small text-muted'>Unique member code</p>
                        <p className='mb-2'>{member.user.uniqueMemberCode}</p>
                    
                    </>}
                    <p className='small text-muted'>Previous record</p>
                    <p className='mb-2'>{member.user.userProfile?.previousRecord ? 'Yes' : 'No'}</p>

                    <p className='small text-muted'>Participant health issues</p>
                    <p className='mb-2'>{member.user.userProfile?.healthIssues || '--'}</p>

                    <p className='small text-muted'>Participant parent details</p>
                    <p className='mb-2'>{member.user.userProfile?.parentDetails || '--'}</p>

                    <p className='small text-muted'>Other relevant information</p>
                    <p className='mb-2'>{member.user.userProfile?.otherRelevantInformation || '--'}</p>
                    {/* 
                    <p className='small text-muted'>Ethnicity</p>
                    <p className='mb-2'>{getEthicity(ethnicities, member.user.userProfile?.bame)}</p> */}

                </SideModal>
            )}
        </>
    )
}

export default MemberItem;