import React, { useState, useContext } from "react";
import { Calendar, Activity, MapPin, Info, Copy, Clock, AlertTriangle, CheckCircle, AlertCircle, Download} from "react-feather";
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AuthContext } from "../../../../../../services/auth/AuthProvider";
import { DEBRIEF_STATUS, SESSION_STATUS } from "../../../../../../utils/constants";
import EditDebrief from "./EditDebrief";
import axios from "axios";
import { Dropdown } from "../../../../../../components/Dropdown";
import { useMutation } from "@apollo/react-hooks";
import { TOGGLE_DEBRIEF_APPROVE } from "../../../../services/grapqhl";
import FormErrors from "../../../../../../components/FormErrors";

const ViewSession = ({ session, circle }) => {
    const { debrief } = session;

    const { currentUser } = useContext(AuthContext);

    const [copy, setCopy] = useState(false);
    const [formErrors, setFormErrors] = useState([]);


    const [showEditDebrief, setShowEditDebrief] = useState(false);

    const isOfficeAdmin = currentUser?.officeMemberships.some(om => om.office?.id === circle.office?.id && om.isAdmin);
    const isCaseLeader = currentUser?.id === circle.leader?.user?.id || currentUser.id === circle.secondLeader?.user?.id
    const canEditDebrief = currentUser.isOrganizationAdmin || isOfficeAdmin || isCaseLeader;

    const [approveSessionDebrief, { loading }] = useMutation(TOGGLE_DEBRIEF_APPROVE, {
        onCompleted: data => {
            if (data?.approveSessionDebrief?.errors?.length > 0) {
                setFormErrors(data.approveSessionDebrief.errors);
                return;
            }
            if (data.approveSessionDebrief.debrief.id) {
                setShowEditDebrief(false);
            }
        }
    })
    const toggleApprove = () => {
        approveSessionDebrief({
            variables: {
                debriefId: debrief.id,
                status: debrief.status === DEBRIEF_STATUS.approved ? DEBRIEF_STATUS.new : DEBRIEF_STATUS.approved
            }
        })
    }
    const onDownloadFile = (file) => {
        axios({
            method: 'get',
            url: file,
            responseType: 'blob'
        }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = downloadUrl;

            const contentDisposition = response.headers['content-disposition'];
            let filename = file.split('/').pop();

            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

        }).catch((error) => {
            console.error('Failed to download file:', error);
        });
    }


    const renderIssuesTitle = () => {
        if (debrief?.otherIssues || debrief.healthAndSafety || debrief.raceEqualityDiversity || debrief.safeguarding) {
            return <><AlertTriangle className="mr-1" /> Issues reported</>
        }
        return <><CheckCircle className="mr-1" /> No Issues reported</>
    }

    const renderMissed = () => {
        return (
            <>
                {session.isOnline ?
                    <>
                        <div className="d-flex align-items-start mb-2">
                            <MapPin className="mr-1" />
                            <p className="mb-1">Online</p>
                        </div>
                    </>
                    : session.mentoringLocation &&
                    <div className="d-flex align-items-start">
                        <MapPin className="mr-1" />
                        <div>
                            <p>{session.mentoringLocation.name} -{session.mentoringLocation.city}</p>
                            <p className="small">
                                {session.mentoringLocation.address1}
                                {session.mentoringLocation.address2 && `, ${session.mentoringLocation.address2}`}
                                {session.mentoringLocation.address3 && `, ${session.mentoringLocation.address3}`}
                                {session.mentoringLocation.zipCode && `, ${session.mentoringLocation.zipCode}`}
                            </p>
                        </div>
                    </div>
                }

                {session.mentoringSessionCategory &&
                    <div className="d-flex align-items-center mb-2">
                        <Info className="mr-1" />
                        <p>{session.mentoringSessionCategory.name}</p>
                    </div>
                }


                <h3 className="d-flex align-items-center mt-3"><AlertTriangle className="mr-1" /> Session Missed</h3>
                <div className="separator mt-1 mb-1"></div>
                <p className="bold">{session.mentoringCancellationReason?.name}</p>
                {session.otherReasonDescription && <p className="newlines mb-2 mt-1">{session.otherReasonDescription}</p>}
                <p className="bold">{session.contactMade ? `${circle.participants[0]?.user?.firstName} made contact` : `${circle.participants[0]?.user?.firstName} did not contact`}</p>
                <p className="newlines mt-2 mb-2">{session.contactChallenges}</p>
                <p className="bold">{session.coordinatorNotified ? 'Coordinator notified' : 'Coordinator not notified'}</p>

                <h3 className="d-flex align-items-center mt-2"><Activity className="mr-1" /> Activity</h3>
                <div className="separator mt-1 mb-1"></div>

                {session.mentoringSessionPlannedTheme ?
                    <>
                        <p className="d-flex align-items-center justify-content-between mb-1">
                            <span className="lead bold">{session.mentoringSessionPlannedTheme?.name}</span>
                            <span className="label label-muted">Planned</span>
                        </p>
                        <p className="newlines">{session.plannedContentDescription}</p>
                    </> : <p>No theme selected</p>
                }

            </>
        )
    }

    const renderDebrief = () => {
        return (
            <>
                {debrief && (
                    <>

                        {session.mentoringSessionCategory &&
                            <div className="d-flex align-items-center mb-2">
                                <Info className="mr-1" />
                                <p>{session.mentoringSessionCategory.name}</p>
                            </div>
                        }
                        <div className="d-flex align-items-start">
                            <Clock className="mr-1" />
                            <p>{debrief.duration} minutes</p>
                        </div>


                        <div className="mt-3 mb-2">
                            <h3 className="d-flex align-items-center"><Activity className="mr-1" /> Activity</h3>
                            <div className="separator mt-1 mb-1"></div>

                            {session.mentoringSessionPlannedTheme && session.mentoringSessionPlannedTheme.id !== debrief.actualMentoringSessionTheme?.id &&
                                <>
                                    <p className="d-flex align-items-center justify-content-between mb-1">
                                        <span className="lead bold">{session.mentoringSessionPlannedTheme?.name}</span>
                                        <span className="label label-muted">Planned</span>
                                    </p>
                                    <p className="newlines">{session.plannedContentDescription}</p>
                                </>
                            }

                            <p className="d-flex align-items-center justify-content-between mb-1">
                                <span className="lead bold">{debrief.actualMentoringSessionTheme?.name}</span>
                                <span className="label label-muted">Actual</span>
                            </p>
                            <p className="newlines">{debrief.actualContentDescription}</p>

                            {debrief.files.length > 0 &&
                                <>
                                    <p className="bold mt-2 mb-1">Attached files</p>
                                    <ul className="list-group">
                                        {debrief.files.map((file, i) => (
                                            <li className="mx-1 px-1" key={`file-${i}`}>
                                                <button onClick={() => onDownloadFile(file)} className="link-reverse d-flex align-items-center">
                                                    <Download className="mr-1" size={18} />{file.split('/').pop()}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            }
                            <p className="lead bold mt-2">{debrief.positiveOutcomes ? 'Positive Outcomes' : 'No Positive Outcomes Entered'}</p>
                            {debrief.positiveOutcomes && <p className="newlines">{debrief.positiveOutcomes}</p>}

                            <p className="lead bold mt-2">{debrief.challenges ? 'Challenges' : 'No challenges reported'}</p>
                            {debrief.challenges && <p className="newlines">{debrief.challenges}</p>}

                            <h3 className="d-flex align-items-center mt-3">{renderIssuesTitle()}</h3>
                            <div className="separator mt-1 mb-2"></div>


                            <div className="d-flex align-items-start mb-2">
                                {debrief.safeguarding ? <AlertCircle size={20} className="text-danger mr-1" /> : <CheckCircle size={20} className="mr-1" />}
                                <div>
                                    <p className="mb-1">{!debrief.safeguarding && 'No'} Safeguarding Issues</p>
                                    <p className="newlines">{debrief.safeguardingDescription}</p>
                                </div>
                            </div>

                            <div className="d-flex align-items-start mb-2">
                                {debrief.healthAndSafety ? <AlertCircle size={20} className="text-danger mr-1" /> : <CheckCircle size={20} className="mr-1" />}
                                <div>
                                    <p className="mb-1">{!debrief.healthAndSafety && 'No'} Health and Safety Issues</p>
                                    <p className="newlines">{debrief.healthAndSafetyDescription}</p>
                                </div>
                            </div>

                            <div className="d-flex align-items-start mb-2">
                                {debrief.raceEqualityDiversity ? <AlertCircle size={20} className="text-danger mr-1" /> : <CheckCircle size={20} className="mr-1" />}
                                <div>
                                    <p className="mb-1">{!debrief.raceEqualityDiversity && 'No'} Race/Equality/Diversity Issue</p>
                                    <p className="newlines">{debrief.raceEqualityDiversityDescription}</p>
                                </div>
                            </div>

                            <div className="d-flex align-items-start mb-2">
                                {debrief.otherIssues ? <AlertCircle size={20} className="text-danger mr-1" /> : <CheckCircle size={20} className="mr-1" />}
                                <div>
                                    <p className="mb-1">{!debrief.otherIssues && 'No'} Other Issues</p>
                                    <p className="newlines">{debrief.otherIssues}</p>
                                </div>
                            </div>
                        </div>
                    </>


                )}
            </>
        )
    }

    const renderOngoing = () => {
        return (
            <>

                {session.isOnline ?
                    <>
                        <div className="d-flex align-items-start mb-2">
                            <MapPin className="mr-1" />
                            <div className="mb-1 d-flex flex-grow-1">
                                <p><a className="text-dark link-underline" href={session.meetingUrl}>Join Meeting</a></p>

                                <span className="ml-auto">
                                    <CopyToClipboard text={session.meetingUrl}
                                        onCopy={() => setCopy(true)}
                                    >
                                        <button>
                                            {copy ? <span className="d-flex"><CheckCircle className="mr-1" /> Copied!</span> : <Copy />}
                                        </button>
                                    </CopyToClipboard>
                                </span>
                            </div>
                            <p className="newlines">{session.meetingDescription}</p>
                        </div>
                    </>
                    : session.mentoringLocation &&
                    <div className="d-flex align-items-start">
                        <MapPin className="mr-1" />
                        <div>
                            <p>{session.mentoringLocation.name} -{session.mentoringLocation.city}</p>
                            <p className="small">
                                {session.mentoringLocation.address1}
                                {session.mentoringLocation.address2 && `, ${session.mentoringLocation.address2}`}
                                {session.mentoringLocation.address3 && `, ${session.mentoringLocation.address3}`}
                                {session.mentoringLocation.zipCode && `, ${session.mentoringLocation.zipCode}`}
                            </p>
                        </div>
                    </div>
                }


                <p className="uppercase small bold text-muted-2 mt-3 mb-2">Planned Session Details</p>
                {session.mentoringSessionCategory &&
                    <div className="d-flex align-items-center mb-2">
                        <Info className="mr-1" />
                        <p>{session.mentoringSessionCategory.name}</p>
                    </div>
                }
                <div className="d-flex align-items-start">
                    {session.mentoringSessionPlannedTheme && <Activity className="mr-1" />}
                    <div>
                        <p>{session.mentoringSessionPlannedTheme?.name}</p>
                        <p className="newlines">{session.plannedContentDescription}</p>
                    </div>
                </div>
            </>
        )
    }



    const renderContent = () => {
        switch (session.status) {
            case SESSION_STATUS.new:
                return renderOngoing();
            case SESSION_STATUS.missed:
                return renderMissed();
            case SESSION_STATUS.completed:
                return renderDebrief();
            default:
                return null;
        }
    }

    return (
        <>

            <div className="d-flex mb-2 align-items-center">
                <h1>Session {session.index} {session.status === SESSION_STATUS.completed && "Debrief"}</h1>
                <div className="ml-auto">
                    {session.status === SESSION_STATUS.completed && canEditDebrief &&
                        <div className="d-flex align-items-center mr-4">

                            {debrief.status !== DEBRIEF_STATUS.approved && <button className="btn btn-outline mr-2" onClick={() => setShowEditDebrief(true)}>Edit</button>}

                            {debrief.status === DEBRIEF_STATUS.new ?
                                <button className="btn mr-1" disabled={loading} onClick={() => toggleApprove()}>Approve</button> :
                                <div className="d-flex align-items-center">
                                    <div className="mr-2 d-flex  flex-column align-items-end">
                                        <p className="d-flex align-items-center"><CheckCircle size={18} className="mr-1" />Approved</p>
                                        <p className="small">by: {debrief.approvedOrRejectedBy?.firstName} {debrief.approvedOrRejectedBy?.lastName} | {debrief.approvedOrRejectedAt}</p>
                                    </div>
                                    <Dropdown>
                                        <li><button disabled={loading} onClick={() => toggleApprove()}>{debrief.status === DEBRIEF_STATUS.approved ? 'Un-Approve' : 'Approve'}</button></li>
                                    </Dropdown>
                                </div>
                            }
                            {formErrors && <FormErrors errors={formErrors} />}

                        </div>
                    }
                </div>
            </div>

            {showEditDebrief ? <EditDebrief currentUser={currentUser} close={() => setShowEditDebrief(false)} session={session} /> :

                <>
                    <p className="mb-2">
                        <span className="label label-muted">{session.isOnline ? 'Online' : 'In Person'}</span>
                    </p>

                    <div className="d-flex align-items-center mb-2">
                        <Calendar className="mr-1" />
                        {moment(session.date).format('DD MMM YYYY')} @ {moment(session.time, 'HH:mm:ss').format('HH:mm')} {session.expectedDuration && <span className="ml-2 small">Expected duration: {session.expectedDuration}m</span>}
                    </div>
                    {renderContent()}
                </>
            }
        </>
    )
}

export default ViewSession;