import React from 'react';
import CaseStepCreate from './CaseStepCreate';

const AddCase = ({ onCancel, toLeader, officeId }) => {

    return (
        <div className='card'>
            <div className='card-body'>
                    <CaseStepCreate
                        onCancel={onCancel}
                        toLeader={toLeader}
                        officeId={officeId}
                    />
            </div>
        </div>
    );

}

export default AddCase;