import React, { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Loader from '../../../../components/Loader';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ADD_CIRCLE_DETAILS, GET_CASE, GET_OFFICE_LEADERS } from '../../services/grapqhl';
import FormErrors from '../../../../components/FormErrors';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import moment from 'moment';
import SaveConfirm from '../../../../components/SaveConfirm';
import { CIRCLE_MODE, CIRCLE_STATUS } from '../../../../utils/constants';

const EditCircleDetails = ({ onCancel, circle, isEdit = false }) => {

    const { data, loading, error } = useQuery(GET_OFFICE_LEADERS, {
        variables: {
            officeId: circle.office.id
        }
    });

    const [formErrors, setFormErrors] = useState([]);

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);


    const [addCircleDetails, { loading: saving }] = useMutation(ADD_CIRCLE_DETAILS, {
        onCompleted: data => {
            if (data.addCircleDetails.errors && data.addCircleDetails.errors.length > 0) {
                setFormErrors(data.addCircleDetails.errors);
                return;
            }
            if (data.addCircleDetails.circle?.id) {
                isEdit ? onCancel() : setShowSaveConfirm(true);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE,
                variables: {
                    circleId: circle.id,
                },
                fetchPolicy: 'network-only'
            }
        ],
    });

    const schema = Yup.object().shape({
        desiredOutcome: Yup.string().required('Desired outcome is required'),
        ...(circle.mode !== CIRCLE_MODE.mentoring && {
            incidentDetails: Yup.string().required('Details are required'),
            incidentDate: Yup.string().required('Date of Referral is required'),
        })

    });

    const {
        control,
        register,
        handleSubmit,
        getValues,

        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });


    const onSubmit = (data, additionalData) => {
        const { reasonForReferral, desiredOutcome, hateCrime, otherAgencyCommunityInvolvement, incidentDate, incidentDetails } = data;
        addCircleDetails({
            variables: {
                circleId: circle.id,
                ...additionalData,
                reasonForReferral: !reasonForReferral ? null : reasonForReferral,
                desiredOutcome: !desiredOutcome ? null : desiredOutcome,
                otherAgencyCommunityInvolvement: !otherAgencyCommunityInvolvement ? null : otherAgencyCommunityInvolvement,
                ...(circle.mode !== CIRCLE_MODE.mentoring && {
                    incidentDate: !incidentDate ? null : incidentDate,
                    incidentDetails: !incidentDetails ? null : incidentDetails,
                    hateCrime
                })
            }
        });
    }


    const handleSaveDraft = (data) => {
        onSubmit(data, { isDraft: true });
    };

    const handleSaveFinal = (data) => {
        onSubmit(data, { isFinal: true });
    };

    const handleEdit = (data) => {
        onSubmit(data, {});
    };

    const handleButtonClick = (handler, validate) => (event) => {
        event.preventDefault();
        if (validate) {
            handleSubmit(handler)();
        } else {
            handler(getValues());  //For save as draft
        }
    };

    if (loading) return <Loader />;
    if (error) return <div>{error.message}</div>;

    if (data?.office) {


        return <>

            <h2>Case details</h2>
            <form className='form mb-4'>
                <div className="row">
                    <div className="col-md-6">
                        {circle.mode !== CIRCLE_MODE.mentoring &&
                            <>
                                <div className="basic-form__group">
                                    <label htmlFor='incidentDate' className='basic-form__text-label'>Date of Incident* (required)</label>
                                    <Controller
                                        control={control}
                                        name="incidentDate"
                                        defaultValue={circle.incidentDate ? moment(circle.incidentDate).toDate() : ''}
                                        render={({field}) => (
                                            <ReactDatePicker
                                                {...field}
                                                dateFormat={'dd/MM/yyyy'}
                                                autoComplete='off'
                                                className="basic-form__text-box picker"
                                                placeholderText="Select date"
                                                selected={field.value}
                                            />
                                        )}
                                    />
                                    {errors.incidentDate && <p className='basic-form__hint'>{errors.incidentDate.message}</p>}
                                </div>

                                <div className="basic-form__group">
                                    <label htmlFor='incidentDetails' className='basic-form__text-label'>Incident details* (required)</label>
                                    <textarea
                                        rows={3}
                                        {...register('incidentDetails')}
                                        defaultValue={circle.incidentDetails}
                                        className="basic-form__text-area"></textarea>
                                    {errors.incidentDetails && <p className='basic-form__hint'>{errors.incidentDetails.message}</p>}
                                </div>

                                <div className="basic-form__group basic-form__group--check">
                                    <span className="mr-2">Is this a hate incident* </span>
                                    {/* <div className='d-flex align-items-center'>
                                        <label className='mr-3'>
                                            <input type="radio" name="hateCrime" value="true" ref={register} />
                                            Yes
                                        </label>
                                        <label>
                                            <input type="radio" name="hateCrime" value="false" ref={register} />
                                            No
                                        </label>
                                    </div> */}
                                    <input
                                        type="checkbox"
                                        className="switchToggle"
                                        {...register('hateCrime')}
                                        id='hateCrime'
                                        defaultChecked={circle.hateCrime} />
                                    <label className="switchLabel" htmlFor='hateCrime'>&nbsp;</label>
                                </div>
                            </>}

                        <div className="basic-form__group">
                            <label htmlFor='desiredOutcome' className='basic-form__text-label'>Desired outcome* (required)</label>
                            <textarea
                                {...register('desiredOutcome')}
                                className="basic-form__text-box"
                                defaultValue={circle.desiredOutcome}
                                rows={3}></textarea>
                            {errors.desiredOutcome && <p className="basic-form__hint">{errors.desiredOutcome.message}</p>}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="basic-form__group">
                            <label htmlFor='reasonForReferral' className='basic-form__text-label'>Reason for referral (optional)</label>
                            <textarea
                                {...register('reasonForReferral')}
                                className="basic-form__text-box"
                                defaultValue={circle.reasonForReferral}
                                rows={3}></textarea>
                        </div>

                        <div className="basic-form__group">
                            <label htmlFor='otherAgencyCommunityInvolvement' className='basic-form__text-label'>Other agency / community involvement (optional)</label>
                            <textarea
                                {...register('otherAgencyCommunityInvolvement')}
                                className="basic-form__text-box"
                                defaultValue={circle.otherAgencyCommunityInvolvement}
                                rows={3}></textarea>
                        </div>
                    </div>
                </div>

                {formErrors && <FormErrors errors={formErrors} />}

                {showSaveConfirm && <SaveConfirm classNames='mt-2 mb-2' message={'Changes saved!'} hideConfirm={setShowSaveConfirm} />}

                {circle.status === CIRCLE_STATUS.draft ?
                    <>
                        <button
                            onClick={handleButtonClick(handleSaveDraft, false)}
                            type="button"
                            disabled={saving}
                            className="btn btn-outline mr-2">Save as draft</button>
                        <button
                            onClick={handleButtonClick(handleSaveFinal, true)}
                            type="button"
                            disabled={saving}
                            className="btn">Save & Start Case</button>
                    </>
                    :
                    <button
                        onClick={handleButtonClick(handleEdit, true)}
                        type="button"
                        disabled={saving}
                        className="btn">Save Edits</button>
                }

                {isEdit && <button type='button' onClick={onCancel} className='ml-2 btn btn-muted'>Cancel</button>}

            </form>
        </>;
    }
    return null;
}

export default EditCircleDetails;