import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_USER } from './services/graphql';
import { useForm } from 'react-hook-form';
import FormErrors from '../../components/FormErrors';
import { AuthContext } from '../../services/auth/AuthProvider';
import ProfileImage from '../../components/ProfileImage';
import defaultImg from '../../images/icons/user.png';

const Profile = () => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [formErrors, setFormErrors] = useState([]);
    const [showCropModal, setShowCropModal] = useState(false);


    const { register, handleSubmit, formState: {
        errors,
    }, reset } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.email,
        }
    });

    const [updateUser, { loading }] = useMutation(UPDATE_USER, {
        onCompleted: data => {
            if (data.updateUser.errors && data.updateUser.errors.length > 0) {
                setFormErrors(data.updateUser.errors);
                return;
            }
            if (data.updateUser.user && data.updateUser.user.id) {
                const cu = currentUser;
                const updatedFields = {
                    firstName: data.updateUser.user.firstName,
                    lastName: data.updateUser.user.lastName,
                    email: data.updateUser.user.email,
                    photoUrl: data.updateUser.user.photoUrl
                }
                setCurrentUser({ ...cu, ...updatedFields})
                setShowCropModal(false);
            }
        }
    });

    const onSubmit = data => {
        const { email, lastName, firstName } = data;
        updateUser({ variables: { email, lastName, firstName } });
    }

    const saveNewAvatar = ({ variables }) => {
        updateUser({
            variables: {
                ...variables,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                email: currentUser.email
            }
        });
    }

    return (
        <div className='card'>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <ProfileImage
                            defaultImg={currentUser.photoUrl || defaultImg}
                            imgName={`photo/${currentUser.id}`}
                            onSave={saveNewAvatar}
                            savingImage={loading}
                            setShowCropModal={setShowCropModal}
                            showCropModal={showCropModal}
                            width={140}
                        />
                    </div>
                    <div className="col-md-7">
                        <h1>Profile</h1>
                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            <div className="basic-form__group">
                                <input
                                    placeholder="First name"
                                    className="basic-form__text-box"
                                    {...register('firstName', { required: 'Please enter first name' })}
                                />
                                {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                            </div>
                            <div className="basic-form__group">
                                <input
                                    placeholder="Last name"
                                    className="basic-form__text-box"
                                    {...register('lastName', { required: 'Please enter last name' })}
                                />
                                {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                            </div>
                            <div className="basic-form__group">
                                <input
                                    placeholder="Email"
                                    className="basic-form__text-box"
                                    {...register('email', { required: 'Please enter email' })}
                                />
                                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                            </div>
                            <button type="submit" disabled={loading} className="btn btn-fullWidth u-display-block">{loading ? 'Saving...' : 'Save'}</button>
                            <button type="button"
                                onClick={() => {
                                    reset({
                                        firstName: currentUser.firstName,
                                        lastName: currentUser.lastName,
                                        email: currentUser.email,
                                    })
                                }}
                                className="btn btn-muted ml-2">Cancel</button>
                            {formErrors && <FormErrors errors={formErrors} />}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;