import React, { memo } from "react";
import { Edit, Trash2 } from "react-feather";
import FormErrors from '../../../components/FormErrors';
import { useForm } from "react-hook-form";
import * as Yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";

const CategoryItem = memo(({ onEdit, loading, onDelete, item, showEdit, setShowEdit, editErrors, type }) => {

    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(Yup.object().shape({
            name: Yup.string().required('Name is required'),
        })),
        mode: 'onSubmit'
    })


    const onSubmit = data => {
        const { name } = data;
        onEdit({ name, id: item.id })

    }


    return <>

        {item.id && showEdit?.id === item.id ?
            <div className="list-item list-item--separator-dark">
                <form className="d-flex align-items-center width-full" onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="text"
                        className="basic-form__text-box mb-0"
                        defaultValue={item.name}
                        {...register('name')} />
                    <div className="ml-1">
                        <button type="submit" disabled={loading} className="btn btn-blue mr-1">Save</button>
                        <button type="button" onClick={() => setShowEdit(null)} className="btn btn-muted">Cancel</button>
                    </div>
                </form>
                {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
                {editErrors && <FormErrors errors={editErrors} />}
            </div> :
            <li className={"list-item list-item--separator-dark"}>
                <p className="d-flex align-items-center">
                    {type === 'SessionBreaks' ? item : item.name}
                </p>
                <div className="ml-auto d-flex align-items-center">
                    {type !== 'SessionBreaks' && <button type="button" className='text-blue d-flex align-items-center mr-2' onClick={() => setShowEdit(item)}><Edit /></button>}
                    <button disabled={loading} type="button" className='d-flex align-items-center text-blue' onClick={() => onDelete(item)}><Trash2 /></button>
                </div>
            </li>
        }
    </>;
}
);

export default CategoryItem;