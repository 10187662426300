
import React, { useState } from 'react';
import { UPDATE_USER } from "../../Profile/services/graphql";
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormErrors from '../../../components/FormErrors';
import ProfileImage from '../../../components/ProfileImage';
import defaultImg from '../../../images/profile-default.svg';
import { RESEND_INVITE } from '../../Leaders/services/graphql';
import SaveConfirm from '../../../components/SaveConfirm';
import { AlertCircle } from 'react-feather';

const EditUser = ({ user, close, setShowMember }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [showCropModal, setShowCropModal] = useState(false);

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);


    const schema = Yup.object().shape({
        firstName: Yup.string().required('firstName is required'),
        lastName: Yup.string().required('lastName is required'),
        email: Yup.string().email().required('email is required'),
    });


    const [resendInvite] = useLazyQuery(RESEND_INVITE, {
        fetchPolicy: 'network-only',
        onCompleted: _ => {
            setShowSaveConfirm(true);
        }
    });

    const {
        register,
        handleSubmit,
        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
        }
    });

    const [updateUser, { loading }] = useMutation(UPDATE_USER, {
        onCompleted: data => {
            if (data.updateUser.errors && data.updateUser.errors.length > 0) {
                setFormErrors(data.updateUser.errors);
                return;
            }
            if (data.updateUser.user && data.updateUser.user.id) {
                close();
                setShowCropModal(false);
                const updatedFields = {
                    firstName: data.updateUser.user.firstName,
                    lastName: data.updateUser.user.lastName,
                    email: data.updateUser.user.email,
                    photoUrl: data.updateUser.user.photoUrl
                }
                setShowMember({ ...user, ...updatedFields })
            }
        }
    });

    const onSubmit = data => {
        const { email, lastName, firstName } = data;
        updateUser({ variables: { email, lastName, firstName, userId: user.id } });
    }

    const saveNewAvatar = ({ variables }) => {
        updateUser({
            variables: {
                ...variables,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                userId: user.id
            }
        });
    }
    return (
        <div className="d-flex">
            <ProfileImage
                defaultImg={user.photoUrl || defaultImg}
                imgName={`photo/${user.id}`}
                onSave={saveNewAvatar}
                savingImage={loading}
                setShowCropModal={setShowCropModal}
                showCropModal={showCropModal}
                width={140}
            />
            <div className='flex-grow-1 pl-2'>
                {showSaveConfirm && <SaveConfirm classNames='mb-0 mt-0 ml-2' message={'Email sent!'} hideConfirm={setShowSaveConfirm} />}

                {!user.active &&
                    <div className='bg-gray p-1 mb-2'>
                        <div className="d-flex">
                            <p className='d-flex align-items-center'><AlertCircle className='mr-1' /> User not active</p>
                            <button
                                type='btn'
                                className='btn ml-2'
                                onClick={() => resendInvite({
                                    variables: { userId: user.id }
                                })}
                            >
                                {loading ? 'Resending...' : 'Resend invite'}
                            </button>
                        </div>
                    </div>
                }

                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="basic-form__group">
                                <label htmlFor='firstName'>First name</label>
                                <input
                                    {...register('firstName')}
                                    placeholder="First name"
                                    className="basic-form__text-box" />
                                {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                            </div>
                        </div>
                        <div className="col-md-6">



                            <div className="basic-form__group">
                                <label htmlFor='lastName'>Last name</label>

                                <input
                                    {...register('lastName')}
                                    placeholder="Last name"
                                    className="basic-form__text-box" />
                                {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="basic-form__group">
                                <label htmlFor='email'>Email</label>
                                <input
                                    {...register('email')}
                                    placeholder="Email"
                                    type='email'
                                    className="basic-form__text-box lowercase" />
                                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="submit" disabled={loading} className="btn btn-fullWidth u-display-block">{loading ? 'Saving...' : 'Save'}</button>
                        <button type="button"
                            onClick={close}
                            className="btn btn-muted ml-2">Cancel</button>
                    </div>

                    {formErrors && <FormErrors errors={formErrors} />}
                </form>
            </div>
        </div>
    );
}

export default EditUser;