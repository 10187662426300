import React, { useState, useContext } from "react";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import { ADD_TIME_LOG_OR_CASE_NOTE, GET_CASE_NOTES, GET_CIRCLE_TIME_LOGS } from "../../../../services/grapqhl";
import { AuthContext } from "../../../../../../services/auth/AuthProvider";
import FormErrors from "../../../../../../components/FormErrors";
import { GET_S3_UPLOAD_URL_QUERY } from "../../../../../ResourceCentre/services/graphql";
import { useForm, Controller } from 'react-hook-form';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from "moment";
import { AlertTriangle } from "react-feather";

const AddNote = ({ circle, close }) => {

    const { currentUser } = useContext(AuthContext);
    const client = useApolloClient();

    const [initLoading, setInitLoading] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [formWarning, setFormWarning] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);


    const onSelectFile = (files) => {
        if (files.length) {
            setSelectedFile(files[0]);
        } else {
            setSelectedFile(null)
        }
    }

    const [addTimeLogOrCaseNote, { loading }] = useMutation(ADD_TIME_LOG_OR_CASE_NOTE, {
        onCompleted: (data) => {
            if (data?.addTimeLogOrCaseNote?.errors?.length > 0) {
                setFormErrors(data.addTimeLogOrCaseNote.errors)
                return
            }
            if (data?.addTimeLogOrCaseNote?.caseNote?.id || data.addTimeLogOrCaseNote?.log?.id) {
                close();
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE_NOTES,
                variables: {
                    circleId: circle.id,
                },
                fetchPolicy: 'network-only'
            },
            {
                query: GET_CIRCLE_TIME_LOGS,
                variables: {
                    circleId: circle.id
                }
            }
        ],
    })


    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: {
            errors,
        },
    } = useForm({
        mode: 'onSubmit'
    });




    const onSubmit = data => {
        const { text, documentName, duration, logDate } = data;

        if ((text === '' && !selectedFile && duration === '')) {
            setFormWarning(true);
            return;
        } else {
            setFormWarning(false);
        }

        if (selectedFile) {
            const extension = selectedFile.name.split('.').pop().toLowerCase();
            const filename = `Document-${new Date().getTime()}.${extension}`;
            setInitLoading(true);
            client.query({
                query: GET_S3_UPLOAD_URL_QUERY,
                variables: {
                    fileName: filename
                }
            }).then(r => {
                setInitLoading(false);
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                axios.put(r.data.s3UploadUrl, selectedFile, options).then(s3r => {
                    addTimeLogOrCaseNote({
                        variables: {
                            circleId: circle.id,
                            ...(text && { text: text, authorId: currentUser.id }),
                            documentName: documentName,
                            ...(duration && { duration: parseFloat(duration) }),
                            ...(logDate && { logDate: moment(logDate).format('DD/MM/YYYY') }),
                            documentUrl: r.data.s3UploadUrl.substr(0, r.data.s3UploadUrl.indexOf('?'))
                        }
                    })
                })
            });
        } else {
            addTimeLogOrCaseNote({
                variables: {
                    circleId: circle.id,
                    ...(text && { text: text, authorId: currentUser.id }),
                    ...(duration && { duration: parseFloat(duration) }),
                    ...(logDate && { logDate: moment(logDate).format('DD/MM/YYYY') }),
                }
            })
        }
    }

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-7">
                    <h2>Add Note</h2>
                    <p>Optional: Leave blank to add Time Log only</p>
                    <div className="basic-form__group">
                        <textarea
                            {...register('text')}
                            className="basic-form__text-box"
                            placeholder="Add your case note here"
                            rows={8}></textarea>
                    </div>

                    <p>Attach a document</p>
                    <div className="basic-form__group">
                        <input
                            {...register('file')}
                            onChange={e => onSelectFile(e.target.files)}
                            type='file'
                            className='basic-form__text-box' />
                    </div>

                    {selectedFile &&
                        <div className="basic-form__group">
                            <input
                                {...register('documentName', {
                                    validate: {
                                        required: value => {
                                            if (!value && selectedFile) return 'Please add a name';
                                            return true;
                                        },
                                    },
                                })}
                                className="basic-form__text-box"
                                type='text'
                                placeholder="Document name" />
                        </div>
                    }
                    {errors.documentName && <p className="basic-form__hint">{errors.documentName.message}</p>}
                </div>
                <div className="col-md-5">
                    <h2>Add Time Log</h2>
                    <p>Optional: Leave blank to add Case Note only</p>
                    <div className="basic-form__group flex-row align-items-center">
                        <input
                            {...register('duration')}
                            className="basic-form__text-box mb-0"
                            placeholder="e.g. 0.5" />
                        <span className="ml-2">Hours</span>
                    </div>

                    <div className="basic-form__group">
                        <p>Date of logged hours</p>
                        <Controller
                            control={control}
                            name="logDate"
                            defaultValue={''}
                            rules={{
                                validate: value => {
                                    const duration = watch('duration');
                                    if (duration && !value) {
                                        return "Please enter a date for the time log!";
                                    }
                                    return true;
                                }
                            }}
                            render={({ field }) => (
                                <ReactDatePicker
                                    {...field}
                                    dateFormat="dd/MM/yyyy"
                                    className="basic-form__text-box picker"
                                    placeholderText="Select date"
                                    autoComplete="off"
                                    maxDate={moment().toDate()}
                                    selected={field.value}
                                    ref={(ref) => {
                                        if (ref && ref.input) {
                                            field.ref(ref.input);
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.logDate && <p className="basic-form__hint">{errors.logDate.message}</p>}

                    </div>
                </div>
            </div>
            <hr className="separator" />
            {formErrors && <FormErrors errors={formErrors} />}
            {formWarning && <p className="d-flex align-items-center mb-2 text-danger"><AlertTriangle className="mr-2" />Enter either a note or a time log!</p>}
            <button disabled={initLoading || loading} className="btn mr-2">Save</button>
            <button className="btn btn-muted" onClick={() => close()} type="button">Cancel</button>
        </form>
    </>;
}

export default AddNote;