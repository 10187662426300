import React from "react";
import { AGREEMENT_STATUS, CIRCLE_STATUS } from "../../../../../../utils/constants";
import { AlertCircle, CheckCircle, Clock, Download, XCircle, File } from "react-feather";
import axios from "axios";
import { formatDate } from '../../../../../../utils/functions';

import styles from '../../ViewCase.module.scss';


const AgreementRepair = ({ circle }) => {

    const onDownloadFile = (url) => {
        axios({
            method: 'get',
            url: url,
            responseType: 'blob'
        }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = downloadUrl;

            // Extract the filename from the Content-Disposition header or use a default name
            const contentDisposition = response.headers['content-disposition'];
            let filename = url.split('/').pop();

            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

        }).catch((error) => {
            console.error('Failed to download file:', error);
        });
    }


    return (
        <>

            {circle.status < CIRCLE_STATUS.agreement_repair ?
                <div className="d-flex align-items-center">
                    <Clock className="mr-1" />
                    <p className="bold">Waiting for Opening Circle to complete</p>
                </div> :

                <>
                    {
                        circle.status > CIRCLE_STATUS.agreement_repair && !circle.currentAgreement ?
                            <>
                                <div className="d-flex align-items-center mb-2">
                                    <AlertCircle className="mr-1" />
                                    <p className="bold">Agreement not reached</p>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <XCircle className="mr-1" />
                                    <p className="bold">No reparation agreement uploaded</p>
                                </div>
                            </>
                            : circle.status === CIRCLE_STATUS.agreement_repair && !circle.currentAgreement ? 
                                <div className="d-flex align-items-center mb-2">
                                    <Clock className="mr-1" />
                                    <p className="bold">Agreement</p>
                                </div> :

                                circle.currentAgreement &&
                                <>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div>
                                            <div className="mb-2 d-flex align-items-center">
                                                {
                                                    circle.currentAgreement?.status === AGREEMENT_STATUS.accepted ?
                                                        <>
                                                            <CheckCircle className="mr-1" />
                                                            <p className="bold">Agreement reached</p>
                                                        </>
                                                        :
                                                        (circle.currentAgreement.status === AGREEMENT_STATUS.rejected || (circle.status > CIRCLE_STATUS.agreement_repair && circle.currentAgreement.status !== AGREEMENT_STATUS.accepted)) ?
                                                            <>
                                                                <AlertCircle className="mr-1" />
                                                                <p className="bold">Agreement not reached</p>
                                                            </> :
                                                            circle.currentAgreement.status === AGREEMENT_STATUS.new && <p className="bold">Agreement</p>
                                                }
                                            </div>


                                            {circle.currentAgreement?.status === AGREEMENT_STATUS.accepted &&
                                                <p>All parties accepted the reparation agreement</p>}
                                        </div>

                                        {circle.currentAgreement?.status === AGREEMENT_STATUS.accepted &&
                                            <button onClick={() => onDownloadFile(circle.currentAgreement.attachmentUrl)} className={styles.agreementLinkTop}>
                                                <File className="mr-1" /> Download Agreement
                                            </button>
                                        }
                                    </div>

                                    {circle.agreements.length > 0 && circle.currentAgreement?.status !== AGREEMENT_STATUS.accepted &&
                                        <ul className="list-group mt-3">
                                            {circle.agreements.map(agreement =>
                                                <li key={agreement.id} className="list-item list-item--separator-dark">
                                                    <div className="d-flex align-items-center width-full">
                                                        <div className={styles.agreementStatus}>
                                                            {agreement.status === 0 ?
                                                                <><Clock className="mr-1" />In review </> :
                                                                agreement.status === 2 ? <>< AlertCircle className="mr-1" /> Rejected</>
                                                                    : agreement.status === 1 && <>< CheckCircle className="mr-1" /> Accepted </>
                                                            }
                                                        </div>
                                                        <div className={styles.agreementMain}>
                                                            {agreement.createdAt && <span className="mr-1">{formatDate(agreement.createdAt)}</span>}
                                                            {agreement.addedBy && <span>{`${agreement.addedBy.firstName} ${agreement.addedBy.lastName}`} </span>}
                                                        </div>
                                                        <button onClick={() => onDownloadFile(agreement.attachmentUrl)} className={styles.agreementLink}>
                                                            <Download className="mr-1" /> Download
                                                        </button>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    }
                                </>
                    }

                    {!circle.conductCircleVirtually &&
                        <>
                            <h2 className="mb-2 mt-3">Circle Chat Summary</h2>
                            <p className="newlines">{circle.summary || 'No summary added'}</p>
                        </>
                    }
                </>

            }
        </>
    )
}

export default AgreementRepair;