import React, { useState, useEffect } from "react";
import { AlertTriangle, XCircle } from "react-feather";
import { useForm } from "react-hook-form";
import FormErrors from "../../../components/FormErrors";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_USER_ASSIGN_TO_OFFICES, GET_ORG_USERS } from "../services/graphql";
import ProfileImage from "../../../components/ProfileImage";
import defaultImg from '../../../images/icons/user.png';

const NewMember = ({ close, organization }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [tempData, setTempData] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);

    const [userErrors, setUserErrors] = useState(null);

    const saveNewAvatar = (avatarObj) => {
        setTempData(avatarObj);
        setShowCropModal(false);
    }

    const defaultOffices = organization.offices.map(office => {
        return {
            id: office.id,
            name: office.name,
            isCircleLeader: false,
            isAdmin: false
        }
    });

    const [offices, setOffices] = useState(defaultOffices);

    const { register, watch, handleSubmit, formState: {
        errors,
    } } = useForm({
        defaultValues: {
            isLeader: false, 
            isOrganizationAdmin: false, 
        }
    });

  
    const [watchIsLeader, watchIsOrganizationAdmin] = watch(['isLeader', 'isOrganizationAdmin']);
    useEffect(() => {
        if (watchIsLeader !== undefined) {
            setOffices(prevOffices => prevOffices.map(office => ({ ...office, isCircleLeader: watchIsLeader })));
        }
    }, [watchIsLeader]);

    useEffect(() => {
        if (watchIsOrganizationAdmin !== undefined) {
            setOffices(prevOffices => prevOffices.map(office => ({ ...office, isAdmin: watchIsOrganizationAdmin })));
        }
    }, [watchIsOrganizationAdmin]);

    const handleOfficeChange = (officeId, field) => {
        setOffices(prevOffices => prevOffices.map(office => office.id === officeId ? ({ ...office, [field]: !office[field] }) : office))
    }


    const [createUserAndAssignToOffices, { loading }] = useMutation(CREATE_USER_ASSIGN_TO_OFFICES, {
        onCompleted: data => {
            if (data?.createUserAndAssignToOffices?.errors?.length > 0) {
                setFormErrors(data.createUserAndAssignToOffices.errors)
                return
            }
            if (data.createUserAndAssignToOffices?.user?.id) {
                setTempData(null);
                close();
            }
        },
        refetchQueries: [{ query: GET_ORG_USERS, variables: { organizationId: organization.id, showAllUsers: true } }]
    })


    const onSubmit = data => {
        const { firstName, lastName, email, isOrganizationAdmin, isLeader } = data;
        const validOffices = offices.filter(office => office.isCircleLeader || office.isAdmin);
       // console.log('data', data, 'valid offices', validOffices);

        if ((isLeader && validOffices.filter(office => office.isCircleLeader).length === 0) || (validOffices.length === 0)) {
            setUserErrors(true)
            return
        } else {
            setUserErrors(false);
        }

        const officesData = validOffices.map(office => {
            return {
                officeId: office.id,
                isCircleLeader: office.isCircleLeader,
                isAdmin: office.isAdmin
            }
        });

        createUserAndAssignToOffices({
            variables: {
                organizationId: organization.id,
                firstName,
                lastName,
                email,
                isOrganizationAdmin,
                offices: officesData,
                ...(tempData && { photoUrl: tempData.variables.photoUrl })
            }
        })
    }


    return <>
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center mb-3 justify-content-between">
                    <h3>Create New Account</h3>
                    <button type="button" onClick={close}><XCircle /></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-8">
                            <div className="row">
                                <div className="col-md">
                                    <div className="basic-form__group">
                                        <input
                                            {...register('firstName', { required: 'Please enter first name' })}
                                            placeholder="First name"
                                            className="basic-form__text-box" />
                                        {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="basic-form__group">
                                        <input
                                            {...register('lastName', { required: 'Please enter last name' })}
                                            placeholder="Last name"
                                            className="basic-form__text-box" />
                                        {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="basic-form__group">
                                <input
                                    {...register('email', { required: 'Please enter email' })}
                                    placeholder="Email"
                                    className="basic-form__text-box" />
                                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                            </div>
                        </div>
                        <div className="col">
                            <ProfileImage
                                btnText="+"
                                defaultImg={(tempData?.variables?.photoUrl) || defaultImg}
                                imgName={`photo/${Date.now()}`}
                                onSave={saveNewAvatar}
                                savingImage={loading}
                                setShowCropModal={setShowCropModal}
                                showCropModal={showCropModal}
                                previewWidth={100}
                                width={140}
                            />
                        </div>
                    </div>

                    <h4 className="mb-3">Set permissions</h4>
                    <div className="row">
                        <div className="col-md">
                            <h4 className="uppercase small bold text-muted-2 underline">Admin access</h4>
                            <div className="basic-form__group basic-form__group--check">
                                <span>Organization Admin </span>
                                <div className="ml-auto d-flex">
                                    <input
                                        type="checkbox"
                                        className="switchToggle"
                                        {...register('isOrganizationAdmin')}
                                        id='isOrganizationAdmin'
                                        defaultChecked={false} />
                                    <label className="switchLabel" htmlFor='isOrganizationAdmin'>&nbsp;</label>
                                </div>
                            </div>
                            <p className="small text-muted mb-2">When on, this person will be able to view and manage all offices</p>
                            <p className="bold mb-2">Office Level Admin</p>
                            <ul className="list-group">
                                {offices.map(office => (
                                    <li className="list-item d-block mb-2" key={`admin-${office.id}`}>
                                        <div className="basic-form__group basic-form__group--check">
                                            <span>{office.name}</span>
                                            <div className="ml-auto d-flex">
                                                <input
                                                    disabled={watchIsOrganizationAdmin}
                                                    type="checkbox"
                                                    className="switchToggle"
                                                    id={`admin-${office.id}`}
                                                    checked={office.isAdmin}
                                                    onChange={() => handleOfficeChange(office.id, 'isAdmin')}
                                                />
                                                <label className="switchLabel" htmlFor={`admin-${office.id}`}>&nbsp;</label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-md">
                            <h4 className="uppercase small bold text-muted-2 underline">Circle learder status</h4>
                            <div className="basic-form__group basic-form__group--check">
                                <span>Qualified circle leader </span>
                                <div className="ml-auto d-flex">
                                    <input
                                        type="checkbox"
                                        className="switchToggle"
                                        {...register('isLeader')}
                                        id='isLeader'
                                        defaultChecked={false} />
                                    <label className="switchLabel" htmlFor='isLeader'>&nbsp;</label>
                                </div>
                            </div>
                            <div style={{ display: watchIsLeader ? 'block' : 'none' }}>
                                <p className="bold mb-2">Member can be assigned cases from:</p>
                                <ul className="list-group">
                                    {offices.map(office => (
                                        <li className="list-item d-block mb-2" key={`leader-${office.id}`}>
                                            <div className="basic-form__group basic-form__group--check">
                                                <span>{office.name}</span>
                                                <div className="ml-auto d-flex">
                                                    <input
                                                        type="checkbox"
                                                        className="switchToggle"
                                                        id={`leader-${office.id}`}
                                                        checked={office.isCircleLeader}
                                                        onChange={() => handleOfficeChange(office.id, 'isCircleLeader')}
                                                    />
                                                    <label className="switchLabel" htmlFor={`leader-${office.id}`}>&nbsp;</label>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="d-flex">
                            <button type="submit" disabled={loading} className="btn btn-fullWidth u-display-block">{loading ? 'Saving...' : 'Send invite'}</button>
                            <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>

                        </div>
                        {userErrors &&
                            <div className="d-flex align-items-center ml-2">
                                <AlertTriangle className="text-danger mr-1" />
                                <div>
                                    <p>No permissions</p>
                                    <p className="small">Please select an Admin or Circle Leader permission to save.</p>
                                </div>
                            </div>}
                    </div>
                    {formErrors && <FormErrors errors={formErrors} />}

                </form>
            </div>

        </div>
    </>;
}

export default NewMember;

