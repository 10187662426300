import React, { useState, useContext} from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_OFFICE_USERS } from "./services/graphql";
import Loader from "../../components/Loader";
import ViewMember from "./components/ViewMember";
import { AuthContext } from "../../services/auth/AuthProvider";

const OfficeTeam = ({ officeId }) => {

    const [showMember, setShowMember] = useState(null);

    const { currentUser } = useContext(AuthContext);


    const { data, loading, error } = useQuery(GET_OFFICE_USERS, {
        variables: {
            officeId
        }
    })

    const renderClStatus = (user, office) => {
        const officeMembership = user.officeMemberships.find(om => om?.office?.id === office.id);
        if (officeMembership && officeMembership.isCircleLeader) {
            return <span className="label label-green ml-1">CL</span>
        }
        return null
    }

    const renderAdminStatus = (user, office) => {
        const officeMembership = user.officeMemberships.find(om => om?.office?.id === office.id);
        if (officeMembership && officeMembership.isAdmin) {
            return <span className="label label-primary">Admin</span>
        }
        return null
    }

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }


    if (showMember) {
        return (
            <ViewMember
                currentUser={currentUser}
                organization={currentUser.organization}
                setShowMember={setShowMember}
                close={() => setShowMember(null)}
                user={showMember}
            />
        )
    }

    if (data?.office?.id) {

        const { office } = data;
        return (
            <>
                <div className="d-flex mb-3 align-items-center">
                    <h1>Team</h1>
                </div>
                {office.users.length > 0 ?
                    <ul className="list-group">
                        {office.users.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(user => (
                            <button onClick={() => setShowMember(user)} className="width-full list-item list-item--separator" key={user.id}>
                                <div className=" d-flex justify-content-between width-full">
                                    <p>{user.firstName} {user.lastName} {renderClStatus(user, office)}</p>
                                    <span>
                                        {renderAdminStatus(user, office)}
                                    </span>
                                </div>
                            </button>
                        ))}
                    </ul> : <p>No office users</p>
                }
            </>
        )
    }
    return null
}

export default OfficeTeam;